import React, { useState } from "react";
import { Input, Button, Typography, Space, Form } from "antd";
import ModalInMiddleSetup from "./modalInMiddleSetup";
import GenericFooter from "./genericFooter";
import BottomBanner from "./bottomBanner";
const SignIn = (props) => {
  const { handleSignIn } = props;
  const [companyKey, setCompanyKey] = useState(null);

  const handleSetCompanyKey = (e) => {
    const newValue = e.target.value.replace(/[^a-zA-Z]/g, "");
    setCompanyKey(newValue);
  };

  return (
    <div>
      <ModalInMiddleSetup modalTitle="Sign in to Anymate" logoColor={"#34AFA5"}>
        <div style={{ textAlign: "center" }}>
          <Space direction="vertical" style={{ width: 400 }}>
            <Typography.Text> Company Name</Typography.Text>
            <Input
              value={companyKey}
              onChange={handleSetCompanyKey}
              onKeyDown={(e) =>
                e.keyCode === 13 ? handleSignIn(companyKey) : ""
              }
            />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Typography.Text type="secondary"></Typography.Text>
              </div>
              <div>
                <Button type="primary" onClick={() => handleSignIn(companyKey)}>
                  Next
                </Button>
              </div>
            </div>
          </Space>
        </div>
        <BottomBanner cta={"Create one here"} ctaPush="/signup">
          Don't have an account?
        </BottomBanner>
      </ModalInMiddleSetup>
      <GenericFooter showWhich="signup" />
    </div>
  );
};
export default SignIn;
