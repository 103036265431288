import { useState, useEffect, useDebugValue } from "react";
import { message } from "antd";
export function useAxiosPublic(api = null, loadOnStart = false, initialArgument = null) {
  /*
NOTE initialArgument cannot be an object since useEffect sees those as new every time.
Use apicall and trigger own updates if argument needs to be an object

loadOnStart is only used if initialArgument is null. Otherwise we load on argument change
If you dont want reloads make sure argument doesnt change or use .apicall to only trigger once 

*/
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [firstLoadIsDone, setFirstLoadIsDone] = useState(false);
  const [latestArgument, setLatestArgument] = useState(null);
  useDebugValue(api ? api.name : null);
  const debug = false;

  //load on start
  useEffect(() => {
    if (loadOnStart && api) {
      debug && console.log("load on start", api && api.name);
      apiCall(initialArgument);
    }
    return () => {
      debug && console.log("unmount", api && api.name);
    };
  }, []);

  //call if argument changes
  useEffect(() => {
    if (initialArgument && api) {
      apiCall(initialArgument);
    }
  }, [initialArgument, api]);

  const clean = () => {
    setData(null);
    setIsLoading(false);
  };

  const fireOnError = (onError, answer) => {
    if (typeof onError === "function") {
      onError(answer);
    } else if (typeof onError === "string") {
      onError === "defaultErrorMessage" ? message.error("Something bad happened :(") : message.error(onError);
    }
  };

  const apiCall = async (argument, onSuccess, onError) => {
    const answer = await update(argument, onSuccess, onError, true);
    return answer;
  };

  const silentRefresh = (argument = initialArgument, onSuccess = null, onError = null) => {
    update(argument, onSuccess, onError, false);
  };

  const update = async (argument, onSuccess, onError, loader = true) => {
    if (api) {
      debug && console.log("start ", api.name);
      setIsLoading(loader);
      setLatestArgument(argument);
      try {
        const answer = await api(argument);

        if (!answer) {
          return null;
        }

        setData(answer.data);
        debug && console.log(answer.data);
        debug && console.log("done ", api.name);

        setIsLoading(false);
        setFirstLoadIsDone(true);
        if (
          answer.data.responseCode !== undefined &&
          answer.data.responseCode !== "Success" &&
          answer.data.responseCode !== "NoChange" &&
          answer.data.responseCode !== "MultipleResults" &&
          typeof onError !== "undefined" &&
          onError !== null
        ) {
          fireOnError(onError, answer);
        } else if (typeof onSuccess === "function") {
          onSuccess(answer);
        } else if (typeof onSuccess === "string") {
          message.success(onSuccess);
        }

        return answer;
      } catch (ex) {
        debug && console.log(api.name);
        debug && console.log(ex.response);

        fireOnError(onError, ex.response);
        setIsLoading(false);
        const failAnswer =
          ex && ex.response
            ? {
                succeeded: false,
                responseCode: ex.response.status,
                message: ex.response.statusText,
              }
            : {
                succeeded: false,
                responseCode: "unknown",
                message: "unknown error",
              };
        debug && console.log(failAnswer);
        setData(failAnswer);
        return failAnswer;
      }
    }
  };

  return { data, apiCall, isLoading, firstLoadIsDone, silentRefresh, clean, latestArgument };
}
