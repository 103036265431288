import React, { useState } from "react";
import ModalInMiddleSetup from "./modalInMiddleSetup";
import { Input, Button, Form, Typography } from "antd";
import Finalizing from "./finalizing";
import FirstSignIn from "./firstSignin";
import GenericFooter from "./genericFooter";
import BottomBanner from "./bottomBanner";
import { useHistory } from "react-router-dom";

const SignUp = (props) => {
  const { handleGetCustomerKey } = props;
  const { push } = useHistory();
  const { status, customerKey } = props.match.params;

  const { Text, Paragraph, Title } = Typography;

  //only used for showing loader
  const [validateStatusCompanyName, setValidateStatusCompanyName] = useState({});

  const [customerKeyForm] = Form.useForm();

  const onGetCustomerKeySuccess = (answer) => {
    setValidateStatusCompanyName({});
    push(`/signup/first/${answer.data.object.customerKey}`);
  };

  const onHandleGetCustomerKey = (values) => {
    setValidateStatusCompanyName({
      validateStatus: "validating",
      hasFeedback: true,
    });
    handleGetCustomerKey(values, onGetCustomerKeySuccess);
  };

  return (
    <React.Fragment>
      {!status && (
        <React.Fragment>
          <ModalInMiddleSetup modalTitle="Create your Organisation" logoColor={"#5E72CA"}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ marginTop: 32, width: 400 }}>
                <Paragraph>
                  Lets get you started. <br />
                  Type a name for your company or organisation.
                </Paragraph>
              </div>
              <div style={{ marginTop: 20, width: 400 }}>
                <Form onFinish={onHandleGetCustomerKey} form={customerKeyForm}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please input a company name",
                      },
                      /*{
                        pattern: new RegExp(/^[a-z0-9 ]+$/i),
                        message: "No special characters",
                      },*/
                      {
                        whitespace: true,
                        message: "Company name cannot only be spaces",
                      },
                    ]}
                    name="requestCustomerName"
                    {...validateStatusCompanyName}
                  >
                    <Input placeholder="Company or Organisation Name" />
                  </Form.Item>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Next
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
            <BottomBanner cta="Sign in" ctaPush={`/signin`}>
              Already got an account?
            </BottomBanner>
          </ModalInMiddleSetup>
          <GenericFooter showWhich="signin" />
        </React.Fragment>
      )}
      {status === "first" && <FirstSignIn {...props} customerKey={customerKey} />}
      {status === "finalizing" && <Finalizing {...props} customerKey={customerKey} />}
    </React.Fragment>
  );
};
export default SignUp;
