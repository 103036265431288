import React, { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Layout } from "antd";
import "./App.css";
import SignIn from "./components/signIn";
import SignUp from "./components/signUp";

import { Helmet } from "react-helmet";
import { useAxiosPublic } from "./hooks/useAxiosPublic";
import { GetCustomerKey, CreateCustomer } from "./services/createCustomerService";

const App = () => {
  const [newTrialData, setNewTrialData] = useState({
    customerName: null,
    customerKey: null,
    firstTeamName: null,
    options: {
      billingPeriod: "Monthly",
      productTier: "Standard",
      approvedPricingQuantity: 0,
      localTimeZoneKey: null,
      countryCode: null,
    },
    fullName: null,
    userName: null,
    password: null,
    email: null,
    emailConfirmed: true,
    termsAndConditionsAccepted: false,
  });

  const getCustomerKey = useAxiosPublic(GetCustomerKey, false, null);
  const createCustomer = useAxiosPublic(CreateCustomer, false, null);

  const onCreateCustomerSuccess = (answer, onSuccess) => {
    typeof onSuccess === "function" && onSuccess();
  };

  const onCreateCustomerFail = (answer) => {
    //todo help ppl if something went wrong
  };

  const handleCreateCustomer = (values, onSuccess) => {
    setNewTrialData({ ...values });
    createCustomer.apiCall(values, (answer) => onCreateCustomerSuccess(answer, onSuccess), onCreateCustomerFail);
  };

  const handleGetCustomerKey = async (values, onSuccess) => {
    const { requestCustomerName } = values;
    getCustomerKey.apiCall(
      { ...values },
      (answer) => {
        const customerKey = answer.data && answer.data.object ? answer.data.object.customerKey : null;
        setNewTrialData({
          ...newTrialData,
          customerName: values.requestCustomerName,
          customerKey,
        });
        typeof onSuccess === "function" && onSuccess(answer);
      },
      "defaultErrorMessage"
    );
  };

  const handleSignIn = (companyKey) => {
    window.location.href = `https://${companyKey}.anymate.app`;
  };

  const signInProps = { handleSignIn };
  const signUpProps = {
    newTrialData,
    setNewTrialData,
    handleGetCustomerKey,
    handleCreateCustomer,
    handleSignIn,
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Anymate Collaborate with Automation</title>
      </Helmet>
      <Layout>
        <Layout.Content>
          <Switch>
            <Route path="/signin/" render={(props) => <SignIn {...props} {...signInProps} />} />
            <Route path="/signup/:status?/:customerKey?" render={(props) => <SignUp {...props} {...signUpProps} />} />
            <Redirect from="/" to="/signin" />
          </Switch>
        </Layout.Content>
      </Layout>
    </React.Fragment>
  );
};

export default App;
