import React from "react";
import { Typography } from "antd";
import { useHistory } from "react-router-dom";

const GenericFooter = (props) => {
  const { showWhich = "signin" } = props;
  const { Text } = Typography;
  let { push } = useHistory();

  return (
    <div style={{ position: "absolute", width: "100%", bottom: "0px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: "24px 0px",
        }}
      >
        <Text>© 2020 - Anymate</Text>
        <a href="https://www.anymate.io/terms" target="_blank">
          Terms and Conditions
        </a>
        {showWhich === "signin" && (
          <a onClick={() => push(`/signin`)}>Sign in</a>
        )}
        {showWhich === "signup" && (
          <a onClick={() => push(`/signup`)}>Sign up</a>
        )}
      </div>
    </div>
  );
};

export default GenericFooter;
