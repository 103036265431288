import React, { useEffect } from "react";

import { useAxiosPublic } from "./../hooks/useAxiosPublic";
import { ProgressStatus } from "./../services/createCustomerService";
import { LoadingOutlined, FrownOutlined } from "@ant-design/icons";
import Confetti from "react-confetti";
import { Typography, Space, Progress, Alert, Button } from "antd";

const Finalizing = (props) => {
  const { customerKey, handleSignIn } = props;

  const progressStatus = useAxiosPublic(ProgressStatus, false, null);
  const statusObject = progressStatus.data ? progressStatus.data.object : null;

  const errorDetected = statusObject
    ? [statusObject.nextAction, statusObject.completedAction].includes("Failure")
    : null;

  useEffect(() => {
    const callForStatus = async () => await progressStatus.apiCall(customerKey);
    //first load
    callForStatus();

    //polling
    const updateInterval = setInterval(callForStatus, 3000);

    //stop polling and redirect on success
    if (statusObject && [statusObject.nextAction, statusObject.completedAction].includes("Confetti")) {
      clearInterval(updateInterval);
      setTimeout(function () {
        handleSignIn(customerKey);
      }, 8000);
    }

    if (statusObject && [statusObject.nextAction, statusObject.completedAction].includes("Finished")) {
      handleSignIn(customerKey);
    }

    return () => clearInterval(updateInterval);
  }, [statusObject && statusObject.nextAction]);

  const loadIcon = <LoadingOutlined style={{ fontSize: 30 }} />;

  const redirectButton = (
    <div>
      <Button type="link" onClick={() => handleSignIn(customerKey)}>
        Click here to be redirected
      </Button>
    </div>
  );

  const customerProgressStatus = [
    { key: "Started", description: "Starting up...", icon: loadIcon },
    {
      key: "GenerateIds",
      description: "Generating id's for the database...",
      icon: loadIcon,
    },
    { key: "Validation", description: "Validationg id's...", icon: loadIcon },
    { key: "SetupDb", description: "Spawning databases...", icon: loadIcon },
    {
      key: "MasterData",
      description: "Populating master data...",
      icon: loadIcon,
    },
    {
      key: "PreparingTeam",
      description: "Preparing teams and users...",
      icon: loadIcon,
    },
    { key: "Security", description: "Checking security...", icon: loadIcon },
    { key: "Confetti", description: redirectButton, icon: <span /> },
    {
      key: "Finished",
      description: redirectButton,
      icon: <span />,
    },
  ];

  const getProgressStatus = () => {
    if (!statusObject) {
      return { description: "loading" };
    }
    return customerProgressStatus.find((status) => status.key === statusObject.nextAction);
  };

  const currentStatus = getProgressStatus();

  const statusPercentage =
    (customerProgressStatus.map((e) => e.key).indexOf(currentStatus.key) / (customerProgressStatus.length - 2)) * 100;

  return (
    <React.Fragment>
      {currentStatus.key === "Confetti" && <Confetti numberOfPieces={1000} recycle={false} />}

      <div style={{ textAlign: "center", marginTop: 100 }}>
        <Space direction="vertical" size="large" style={{ width: 550 }}>
          <div style={{ marginBottom: 24 }}>
            <Typography.Text className="titlestyle">
              {errorDetected ? (
                "woooops"
              ) : currentStatus.key === "Confetti" || currentStatus.key === "Finished" ? (
                "Your trial is ready"
              ) : (
                <span>
                  We are getting things ready <br />
                  <Typography.Text style={{ fontSize: 14 }}>
                    You need to validate your email before you can login <br /> Please check your email now
                  </Typography.Text>
                </span>
              )}
            </Typography.Text>
          </div>
          {errorDetected ? <span /> : currentStatus.icon}
          <Progress
            percent={statusPercentage}
            showInfo={false}
            status={errorDetected ? "exception" : statusPercentage === 100 ? "success" : "active"}
            trailColor={"#fff"}
          />
          {errorDetected ? (
            <Alert
              message="This is awkward. "
              description="Something went wrong. Please try again. If the problem persists please reach out and we will get things sorted "
              type="error"
            />
          ) : (
            <div>{currentStatus.description}</div>
          )}
        </Space>
      </div>
    </React.Fragment>
  );
};

export default Finalizing;
