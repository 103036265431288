import React from "react";
import { Typography, Button } from "antd";
import { useHistory } from "react-router-dom";

const BottomBanner = (props) => {
  const {
    bannerColor = "#34AFA5",
    textColor = "#fff",
    ctaPush,
    cta,
    children,
  } = props;
  let { push } = useHistory();
  return (
    <div
      style={{
        width: "100%",
        height: 42,
        backgroundColor: bannerColor,
        marginTop: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography.Text style={{ color: textColor }}>
        {children}
        {ctaPush && (
          <Button
            type="text"
            style={{ color: textColor }}
            onClick={() => push(ctaPush)}
          >
            <div style={{ fontWeight: "bold", verticalAlign: "top" }}>
              {cta}
            </div>
          </Button>
        )}
      </Typography.Text>
    </div>
  );
};

export default BottomBanner;
