import React, { useEffect } from "react";
import FullLogo from "./../img/logo/fullLogo";
import { Input, Typography, Form, Checkbox, Button } from "antd";
import { CustomerKeyAvailable } from "../services/createCustomerService";
import { useAxiosPublic } from "./../hooks/useAxiosPublic";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "../hooks/useWindowSize";

const FirstSignIn = (props) => {
  const { newTrialData, customerKey, handleGetCustomerKey, handleCreateCustomer } = props;
  const { push } = useHistory();
  const { width } = useWindowSize();

  const customerKeyAvailable = useAxiosPublic(CustomerKeyAvailable, false, null);
  const [customerForm] = Form.useForm();

  const onCustomerKeySuccess = () => {
    customerForm.resetFields();
    customerForm.validateFields(["customerKey"]);
  };

  useEffect(() => {
    if (customerKey === undefined) {
      push(`/signup`);
    }

    if (customerKey !== newTrialData.customerKey) {
      handleGetCustomerKey({ requestCustomerName: customerKey }, () => onCustomerKeySuccess());
    } else {
      customerForm.validateFields(["customerKey"]);
    }
  }, [customerKey]);

  const handleValuesChange = (changed, all) => {
    //not used right now
  };

  const onNewSuccess = (customerKey) => {
    push(`/signup/finalizing/${customerKey}`);
  };

  const handleFormFinish = (values) => {
    let sendObject = { ...values };
    sendObject.termsAndConditionsAccepted = sendObject.termsAndConditionsAccepted === true ? "1.0.0" : false;
    handleCreateCustomer(sendObject, () => onNewSuccess(sendObject.customerKey));
  };

  const basicRules = [
    { required: true, message: "This field is required" },
    { whitespace: true, message: "This field is required" },
    { max: 60, message: "Please shorten this" },
  ];

  const breakpointForSingleFile = 984;

  return (
    <React.Fragment>
      {width > breakpointForSingleFile && (
        <div className="split left" style={{ backgroundColor: "#91C1D9" }}>
          <div style={{ width: 200, margin: "12px 24px" }}>
            <FullLogo color={"#fff"} onClick={() => push(`/`)} className="clickable" />
          </div>
          <div className="centered" style={{ width: 450 }}>
            <Typography.Text style={{ fontWeight: 800, color: "#fff", fontSize: 26 }}>
              The ultimate collaboration tool between software robots and the humans that use them
            </Typography.Text>
          </div>
        </div>
      )}

      <div
        className={width > breakpointForSingleFile ? "split right" : "notsplit whitebg"}
        style={{ backgroundColor: "#fff" }}
      >
        <div className="centered">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <Typography.Text className="titlestyle">
                Welcome! <br /> Create your free trial and get started
              </Typography.Text>
            </div>
            <div
              style={{
                width: 400,
                marginTop: 24,
                padding: 6,
                textAlign: "left",
              }}
            >
              <Form
                initialValues={{ ...newTrialData }}
                form={customerForm}
                layout="vertical"
                onValuesChange={handleValuesChange}
                onFinish={handleFormFinish}
              >
                <Form.Item name="customerName" label="Company Name" rules={[...basicRules]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="customerKey"
                  label="Unique Link"
                  validateTrigger="onBlur"
                  hasFeedback={true}
                  rules={[
                    { whitespace: true, message: "This field is required" },
                    { max: 30, message: "Please shorten this" },
                    (formProps) => ({
                      validator: async (rule, value) => {
                        if (value) {
                          const answer = await customerKeyAvailable.apiCall(value);
                          if (answer.data.object === true) {
                            return Promise.resolve("Link is available");
                          }
                        } else {
                          return Promise.reject("Link is required");
                        }
                        return Promise.reject("Link is unavailable");
                      },
                    }),
                  ]}
                >
                  <Input suffix=".anymate.app" />
                </Form.Item>

                <Form.Item
                  name="userName"
                  label="User Name"
                  rules={[
                    ...basicRules,
                    {
                      pattern: new RegExp(/^\S*$/),

                      message: "no spaces in username please",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  validateTrigger="onBlur"
                  rules={[
                    ...basicRules,
                    {
                      pattern: new RegExp(
                        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                      ),
                      message: "This does not look like an email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <div style={{ textAlign: "left" }}>
                  <Form.Item
                    name="termsAndConditionsAccepted"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject("Please read and accept the terms and conditions"),
                      },
                    ]}
                    wrapperCol={{
                      span: 24,
                    }}
                  >
                    <Checkbox>
                      In order to comply with data protection laws, please accept our{" "}
                      <a href="https://www.anymate.io/terms" target="_blank">
                        terms and conditions.
                      </a>{" "}
                      (opens in a new tab)
                    </Checkbox>
                  </Form.Item>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Create
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FirstSignIn;

/*

<Form.Item label="Name of first team">
                  <span>
                    You can always change this later. <br />
                    We just need 1 team to get started
                  </span>
                  <Form.Item name="firstTeamName" noStyle rules={[...basicRules]}>
                    <Input />
                  </Form.Item>
                </Form.Item>


 <Form.Item
                  name="fullName"
                  label="Full Name"
                  rules={[...basicRules]}
                >
                  <Input />
                </Form.Item>

                <Form.Item label="Password">
                  <span>
                    Rules: At least 9 long and at least <b>good</b> on the scale
                  </span>
                  <Form.Item
                    validateTrigger={
                      passwordScoreHasBeenOver2 ? "onChange" : "onBlur"
                    }
                    name="password"
                    noStyle
                    rules={[
                      ...basicRules,
                      {
                        pattern: new RegExp(/^\S*$/),
                        message: "No spaces in the password",
                      },
                      {
                        min: 9,
                        message: "password needs to be at least 9 long",
                      },
                      (formProps) => ({
                        validator: async (rule, value) => {
                          if (currentPasswordScore > 2) {
                            return Promise.resolve("");
                          }
                          return Promise.reject(
                            "Password is not strong enough"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <PasswordStrengthBar
                    password={currentTypedPassword}
                    onChangeScore={(score) => setCurrentPasswordScore(score)}
                  />    </Form.Item>


     */
