import React from "react";
import FullLogo from "../img/logo/fullLogo";
import { Typography } from "antd";
import { useHistory } from "react-router-dom";

const ModalInMiddleSetup = (props) => {
  let { push } = useHistory();

  const {
    children,
    width = 560,
    modalTitle = null,
    contentStyle = {},
    logoColor = "#5E72CA",
  } = props;
  return (
    <div>
      <div style={{ width: 200, margin: "12px 24px" }}>
        <FullLogo
          color={logoColor}
          onClick={() => push(`/`)}
          className="clickable"
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div
          className="mainCard"
          style={{
            width: width,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: 24 }}>
            {modalTitle && (
              <Typography.Text type="secondary" strong style={{ fontSize: 28 }}>
                {modalTitle}
              </Typography.Text>
            )}
          </div>
          <div style={{ width: "100%", ...contentStyle }}> {children}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalInMiddleSetup;
