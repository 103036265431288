import http from "./httpService";

export function GetCustomerKey(sendObject) {
  return http.post(`/api/CreateCustomer/GetCustomerKey/`, sendObject);
}

export function CustomerKeyAvailable(key) {
  return http.get(`/api/CreateCustomer/CustomerKeyAvailable/${key}`);
}

export function ProgressStatus(customerKey) {
  return http.get(`/api/CreateCustomer/ProgressStatus/${customerKey}`);
}

export function CreateCustomer(sendObject) {
  return http.post(`/api/CreateCustomer/New`, sendObject);
  /*
{
  "customerName": "string", <-- smid op i state
  "customerKey": "string", <-- smid op i state
  "firstTeamName": "string", <-- blank
  "options": {
    "billingPeriod": "Monthly",
    "productTier": "Standard",
    "approvedPricingQuantity": 0,
    "localTimeZoneKey": "string",
    "countryCode": "string"
  },
  "fullName": "string", <-- your name for first user
  "userName": "string", <-- credentials agtig
  "password": "string", <-- 
  "email": "string", <-- din email
  "emailConfirmed": true <-- bare send true
  "termsAndConditionsAccepted": 1

}

*/
}
